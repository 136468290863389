import './App.css';
import Home from './pages/Home'
import AboutUs from './pages/AboutUs'
import Contact from './pages/ContactUs'
import BoilerPage from './pages/BoilerService'
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';

function App() {

  

  return (
    <BrowserRouter>
    <ScrollToTop />
      <Routes>
        <Route path="/" >
          <Route index element={<Home />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/boilerservice" element={<BoilerPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
