import React,{useState} from 'react'
import logo from '../assets/logo.png'
import { NavLink } from 'react-router-dom';
import './navbar.css'
import { RiMenuLine, RiCloseLine } from 'react-icons/ri';




const Navbar = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='navbar__container'>
        <div className="logo__container">
          <img src={logo} alt="logo" className='navbar__logo'/>
        </div>
        <div className="link__container">
          <nav>
            <ul>
              <li>
                <NavLink to="/" activeClassName="active">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/aboutUs" activeClassName="active">
                  About Us
                </NavLink>
              </li>

              <li>
                <NavLink to="/boilerservice" activeClassName="active">
                  Boiler Service
                </NavLink>
              </li>

              <li>
                <NavLink to="/contact" activeClassName="active">
                  Contact Us
                </NavLink>
              </li>
              
            </ul>
          </nav>
        </div>

        <div className="menu-icon" onClick={toggleNavbar}>
            {isOpen ? '' : <RiMenuLine />}
        </div>

        <div className={`mobile__navbar ${isOpen ? 'open' : ''}`}>
          <div className='close__icon__container'>
            <RiCloseLine className='close__icon' onClick={toggleNavbar}/>
          </div>
          
          <nav>
              <ul>
                <li>
                  <NavLink to="/" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/aboutUs" activeClassName="active">
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/boilerservice" activeClassName="active">
                    Boiler Service
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/contact" activeClassName="active">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </nav>
        </div>

    </div>
  )
}

export default Navbar
