import React, { useState } from 'react'
import './contactus.css'
import emailjs from 'emailjs-com';
import MapContainer from './MapContainer';

const Contactus = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [notification, setNotification] = useState({
    message: '',
    isSuccess: false,
  });

  

  const handleSubmit = (e) => {

    if (formData.honeypot != null) {
      // Handle as spam (e.g., reject the submission)
      console.log('Submission detected as spam');
      setNotification({ message: 'Submission detected as spam', isSuccess: false });
      console.log(formData.honeypot)
      return;
      
    }

    e.preventDefault();

    emailjs
      .sendForm(
        'service_5f1vxce', // Replace with your EmailJS service ID
        'template_506cku9', // Replace with your EmailJS template ID
        e.target,
        'R4B22cQUumaDOoIST' // Replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          setNotification({ message: 'Email sent successfully', isSuccess: true });
          setFormData({
            name: '',
            email: '',
            subject: '',
            message: '',
          });
        },
        (error) => {
          console.error('Email sending failed:', error.text);
          setNotification({ message: 'Email sending failed', isSuccess: false });
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
        <div className="home__contactus__page">
          <div className="contactus__left__page">
            <div className="contactus__company__details">
              <h1 className="company__name">Promec Pte Ltd</h1>
              <p className="company__address">Tampines North Drive 1 #06-08 T-space</p>
              <p className="company__address">Singapore S28559</p>
              <p className="company__address">UEN Number: 202216128Z</p>
              <p className="company__address">HP: 97820807</p>
              <p className="company__address">Email: sales@promec.com.sg</p>
            </div>

            <form onSubmit={handleSubmit}>
                    <div className="contactus__form__group" style={{display:'none'}}>
                      <label htmlFor="honeypot">Full Name</label>
                      <input type="text" id="honeypot" name="honeypot"/>
                    </div>
                    <div class="contactus__form__group">
                    <label for="name" >YOUR NAME (REQUIRED)</label>
                    <input type="text" id="name" name="name" value={formData.name}
                    onChange={handleInputChange} required/>
                    </div>
                    <div class="contactus__form__group">
                    <label for="email" >YOUR EMAIL (REQUIRED)</label>
                    <input type="email" id="email" name="email" value={formData.email}
                    onChange={handleInputChange} required />
                    </div>
                    <div class="contactus__form__group">
                    <label for="subject" >SUBJECT</label>
                    <input type="text" id="subject" name="subject" value={formData.subject}
                    onChange={handleInputChange} />
                    </div>
                    <div class="contactus__form__group">
                    <label for="message" >YOUR MESSAGE</label>
                    <textarea id="message" name="message" rows="4" value={formData.message}
                    onChange={handleInputChange}></textarea>
                    </div>
                    <button type="submit">Send</button>

                    {notification.message && (
                    <div className={notification.isSuccess ? 'success-notification' : 'error-notification'}>
                        {notification.message}
                    </div>
                  )}
                </form>
          </div>
            <div className="contactus__right__page">
                <MapContainer />
            </div>
         </div>
  )
}

export default Contactus
