import React from 'react'
import './headertitle.css'

const HeaderTitle = ({title,width,isHomePage,fontSize}) => {
  return (
            <div className="headertitle__container">
              { !isHomePage && <div className="grey__line" style={{ width}}></div>}
              <h1 className="container__title" style={{fontSize}}>{title}</h1>
              { !isHomePage && <div className="grey__line" style={{ width}}></div>}
            </div>
  )
}

export default HeaderTitle
