import React from 'react'
import './enquiry.css'
import landscape from '../assets/landscape.jpg'
import { Link } from 'react-router-dom'

const Enquiry = () => {
  return (
    <div className="home__inquiry__container">
          <div className="inquiry__image__container">
            <img src={landscape} alt="landscape" />
          </div>
          <div className="inquiry__title__container">
            <h1 className="inquiry__title">Got a question or enquiry? Call us at:</h1>
            <h1 className="inquiry__subtitle">97820807</h1>
            <h1 className="inquiry__subtitle">or</h1>

            <Link to='/contact'>
            <button className="inquiry__button">Drop us a message</button>
            </Link>
          </div>
    </div>
  )
}

export default Enquiry
