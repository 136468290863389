import React from 'react'
import './services.css'
import HeaderTitle from './HeaderTitle'
import { Link } from 'react-router-dom';


const Services = ({ backgroundColor,otherServices,isHomePage,isServicePage }) => {

  
  return (
    <div className="services__container" style={{ backgroundColor}}>

      <div className="services__header">
        <HeaderTitle title = {otherServices ? otherServices : 'What we do'} isHomePage={isHomePage} />
      </div>
      <div className="services__card__container">

        <Link to='/boilerservice'>
        <div className="services__card1 ">
              <div className="service__text__container">
              <h1 className="card__header">Our boiler services</h1>
              <p className="card__description">Our company is managed by an industry veteran with more than three decades 
              of Boiler Industrial experience. He has supported various industries, such as Food and Beverages (F&B), 
              Laundry Servicing, by providing efficient services and support. </p>
            </div>
        </div>
        </Link>
        <Link to='/boilerservice'>
        <div className="services__card1 ">

            <div className="service__text__container">
              <h1 className="card__header">Our capabilities</h1>
              <p className="card__description">
                <ul>
                  <li>Managing turn-key projects</li>
                  <li>M.O.M & NEA submissions</li>
                  <li>Professional Engineer(PE) calculations and endorsements</li>
                  <li>Steam Engineering Consultancy</li>
                  <li>Hot Water Solar System</li>
                  <li>Thermal Oil Boiler</li>
                </ul>
              </p>
            </div>
        </div>
        </Link>
      </div>
          
    </div>
  )
}

export default Services
