import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import { Carousel } from 'react-responsive-carousel';
import './gallery.css';
import gallery1 from '../assets/gallery1.jpg'
import gallery2 from '../assets/gallery2.jpg'
import gallery3 from '../assets/gallery3.jpg'
import gallery4 from '../assets/gallery4.jpg'
import gallery5 from '../assets/gallery5.jpg'
import gallery6 from '../assets/gallery6.jpg'
import gallery7 from '../assets/gallery7.jpg'
import gallery8 from '../assets/gallery8.jpg'
import HeaderTitle from './HeaderTitle';

const Gallery = () => {


    const galleryData = [
        {
          imageSrc: gallery1,
          legend: 'Customer visit outside Miura HQ, Japan',
        },
        {
          imageSrc: gallery2,
          legend: 'Miura EX750 boiler in operation',
        },
        {
          imageSrc: gallery3,
          legend: 'Steam header and water tank installation',
        },
        {
          imageSrc: gallery4,
          legend: 'Newly arrived Miura 2 Tonne Diesel boiler',
        },
        {
          imageSrc: gallery5,
          legend: 'Miura EX500 high pressure boiler cargo offloading',
        },
        {
          imageSrc: gallery6,
          legend: 'Water softener installation inside boiler room',
        },
        {
          imageSrc: gallery7,
          legend: '1.5 tonne Fulton Boiler in operation',
        },
        {
          imageSrc: gallery8,
          legend: 'Steam piping installation on site',
        },
      ];

  return (
    
    <div className="gallery__container">

        <div className="title__container">
            <HeaderTitle title="Gallery" isHomePage='true'/>
        </div>
        <div className="gallery">
            <Carousel showArrows={true} showThumbs={true} dynamicHeight={true} autoPlay={3000} infiniteLoop>
            {galleryData.map((item, index) => (
            <div key={index}>
              <img src={item.imageSrc} alt={`Image ${index + 1}`} />
              <p className="legend">{item.legend}</p>
            </div>
          ))}
            </Carousel>
        </div>
        
    </div>
    
  );
};

export default Gallery;

