import React from 'react'
import './boilerservice.css'
import Navbar from '../components/Navbar'
import image5 from '../assets/image 3.jpg'
import { Link } from 'react-router-dom';
import Enquiry from '../components/Enquiry';
import Footer from '../components/Footer';
import HeaderTitle from '../components/HeaderTitle';
import OtherServices from '../components/OtherServices'

const BoilerService = () => {
  return (
    <>
      <Navbar />
      <div className="boiler__service__container">
        <div className="boiler__service__hero__container">
            <div className="boiler__service__image__container">
              <img src={image5} alt="image1" />
            </div>
            <div className="boiler__service__title__container">
              <h1 className="boiler__service__title">Boiler Services</h1>
              <Link to="/contact">
                <button className='service__contactus__button'>Contact Us</button>
              </Link>
            </div>
            <div className="boiler__service__text__container">

                <div className="boilder__service__header">
                  <HeaderTitle title="Boiler Servicing, Installation & Troubleshooting" />
                </div>
                
                <p className="header__description">
                  Boiler services are our core specialisation. We carry out 
                  Boiler Installation work on-site and installation of steam 
                  piping for the boiler system.
                </p>

                <p className="header__description">
                  We also service the boiler annually for inspection 
                  by Professional Engineer (PE) 
                  and Boiler Troubleshooting.
                </p>

                <h2 className="boilder__service__subheader">
                  Our services include:
                </h2>

                <ul>
                  <li>Boiler Installation</li>
                  <li>Boiler Servicing & Troubleshooting</li>
                  <li>Boiler Tubes Renewal</li>
                  <li>M.O.M & NEA (Pollution Control Department) submissions</li>
                  <li>Hydrostatic Pressure Testing</li>
                  <li>Piping System
                    <ul>
                      <li>Steam Piping</li>
                      <li>Water Piping</li>
                      <li>Fuel Piping</li>
                      <li>Chimney Installation inside Piping System</li>
                    </ul>
                  </li>
                  
                  <li>General Trading
                      <ul>
                        <li>Boiler Spare Parts</li>
                        <li>Industry Spare Parts (Pipes, Fittings etc)</li>
                      </ul>
                  </li>
                </ul>

            </div>
            <div className="enquiry__container">
              <Enquiry />
            </div>

            <div className="service__container">
              <OtherServices />
            </div>
        </div>
      </div>
      <Footer backgroundColor="#F4F4F4"/>
    </>
  )
}

export default BoilerService
