import React from 'react'
import './mapcontainer.css'

const MapContainer = () => {

const mapStyle = {
        width: '100%',
        height: "auto",
        border: '0',
};

  return (
    <div className='map__container'>
      <div>
      <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.688876737075!2d103.93204647477835!3d1.363338161532741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da3d9fd349707d%3A0x14c693bb2805360e!2sTampines%20North%20Dr.%201%2C%20Singapore!5e0!3m2!1sen!2slk!4v1694675443456!5m2!1sen!2slk"
      className='google__map'
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
      ></iframe>
      </div>
    </div>
  )
}

export default MapContainer
