import React from 'react'
import './contactus.css'
import image4 from '../assets/main3.jpg'
import Navbar from '../components/Navbar'
import Contactus from '../components/Contactus'
import Footer from '../components/Footer'

const ContactUs = () => {
  return (
    <>
    <Navbar />
    <div className='contact__page__container'>
        <div className="contact__hero__container">
            <div className="contact__image__container">
              <img src={image4} alt="image1" />
            </div>
            <div className="contact__title__container">
              <h1 className="contact__title">Contact Us</h1>
            </div>
        </div>

        <div className="contactus__container">
          <Contactus />
        </div>
    </div>
    <Footer />
    </>
  )
}

export default ContactUs
