import React from 'react'
import Navbar from '../components/Navbar'
import './home.css'
import image2 from '../assets/image 2.jpg'
import Footer from '../components/Footer'
import Services from '../components/Services'
import Clients from '../components/Clients'
import Enquiry from '../components/Enquiry'
import Contactus from '../components/Contactus'
import { Link } from 'react-router-dom'
import Gallery from '../components/Gallery'

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="header__container">
        <div className="home__header__container">
          <div className="header__image__container">
            <img src={image2} alt="image1" />
          </div>
          <div className="header__title__container">
            <h1 className="header__title">Promec Pte Ltd</h1>
            <h2 className="header__subtitle">Boiler-Fabrication-Trading</h2>
            <p className="header__description">Established since May 2022</p>
            <Link to='aboutUs'>
              <button className="header__button">About Us</button>
            </Link>
          </div>
        </div>

        <div className="gallery__container">
          <Gallery />
        </div>

        <div className="service__container">
          <Services backgroundColor="#eaeaea" isHomePage='true' />
        </div>
        
        <div className="client__container">
          <Clients isHomePage='true' />
        </div>
        
        <div className="enquiry__container">
          <Enquiry />
        </div>

        <div className="contactus__container">
          <Contactus />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
