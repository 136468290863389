import React from 'react'
import './clients.css'
import client1 from '../assets/client1.jpg'
import client2 from '../assets/client2.png'
import client3 from '../assets/client3.jpg'
import client4 from '../assets/client4.png'
import client5 from '../assets/client5.png'
import client6 from '../assets/client6.PNG'
import client7 from '../assets/client7.png'
import client8 from '../assets/client8.png'
import client9 from '../assets/client9.png'
import client10 from '../assets/client10.png'
import client11 from '../assets/client11.PNG'
import client12 from '../assets/client12.png'
import HeaderTitle from './HeaderTitle'

const Clients = ({isHomePage}) => {
  return (
    <div className="client__page__container">


          <div className="client__title__container">
            <HeaderTitle title = 'Companies we worked with' isHomePage={isHomePage} />
          </div>

          

          
          <div className="client__icon__container">
            <div className="row__icon__container">

              <div className="icon__container">
                <img src={client1} alt="client1" />
              </div>
              <div className="icon__container">
                <img src={client2} alt="client2" />
              </div>
              <div className="icon__container">
                <img src={client3} alt="client3" />
              </div>
              <div className="icon__container">
                <img src={client4} alt="client4" />
              </div>
            </div>
            
            <div className="row__icon__container">
              <div className="icon__container">
                <img src={client5} alt="client5" />
              </div>
              <div className="icon__container">
                <img src={client6} alt="client6" />
              </div>
              <div className="icon__container">
                <img src={client7} alt="client7" />
              </div>
              <div className="icon__container">
                <img src={client8} alt="client8" />
              </div>
          </div>

          <div className="row__icon__container">
              <div className="icon__container">
                <img src={client9} alt="client9" />
              </div>

              <div className="icon__container">
                <img src={client10} alt="client10" />
              </div>
              <div className="icon__container">
                <img src={client11} alt="client11" />
              </div>
              <div className="icon__container">
                <img src={client12} alt="client12" />
              </div>
          </div>
          </div>
    </div>
  )
}

export default Clients
