import React from 'react'
import './otherServices.css'
import image3 from '../assets/image 3.jpg'
import HeaderTitle from './HeaderTitle'

const otherServices = ({backgroundColor,isHomePage}) => {
  return (
    <div>
        <div className="otherservices__card__container" style={{ backgroundColor}}>

            <HeaderTitle title = 'Other Services' isHomePage={isHomePage} />

            <div className="otherservices__container">
                <div className="otherservices__hero__card">
                    <img src={image3} alt="image1" className='otherservices__card__image'/>
                    <div className="otherservices__card__overlay"></div>
                    
                    <div className="otherservices__card__text__container">
                        <h2 className="otherservices__card__title">Boiler Services</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default otherServices
