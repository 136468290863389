import React from 'react'
import './aboutus.css'
import image4 from '../assets/main1.jpg'
import Navbar from '../components/Navbar'
import Services from '../components/Services'
import Clients from '../components/Clients'
import Enquiry from '../components/Enquiry'
import Footer from '../components/Footer'
import HeaderTitle from '../components/HeaderTitle'

const AboutUs = () => {
  return (
    <>
      <Navbar />
      <div className='aboutus__page__container'>
        <div className="aboutus__hero__container">
            <div className="aboutus__image__container">
              <img src={image4} alt="image1" />
            </div>
            <div className="aboutus__title__container">
              <h1 className="aboutus__title">About Us</h1>
            </div>
        </div>
        <div className="aboutus__text__container">
          <div className="aboutus__text__container__header">
            <HeaderTitle title='Engineering & Industrial Servicing' width = '15%'/>
          </div>
          <p className="aboutus__text__container__description">
            Promec was incorporated in 2022 by an industry veteran of 35 years. 
            Promec offers a wealth of experience in the supply and maintainence of 
            industrial Boilers and turn key projects. Promec's main offerings include: 
            Project Management and Consultancy Services, Boiler Servicing & Troubleshooting, 
            Industrial Spare Parts and other turn-key projects.
          </p>
        </div>

        <div className="service__container">
          <Services />
        </div>
        
        <div className="client__container">
          <Clients />
        </div>
        
        <div className="enquiry__container">
          <Enquiry />
        </div>

      </div>
      <Footer />
    </>
  )
}

export default AboutUs
